<template>
  <a-form
    ref="createPocketForm"
    :model="pocketForm"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    label-align="left"
    @finish="handleFinish"
    @finishFailed="handleFinishFailed"
  >
    <a-form-item label="Name" name="name">
      <a-input v-model:value="pocketForm.name" placeholder="Pocket name" />
    </a-form-item>
    <a-button type="primary" html-type="submit" class="btn btn-success px-5" :loading="loading">Create</a-button>
  </a-form>
</template>
<script>
import { defineComponent, reactive, toRaw } from 'vue'
import { useStore } from "vuex"
import { useRouter } from 'vue-router'
import { ref }from 'vue'
import { notification } from "ant-design-vue";

export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()

    const pocketForm = reactive({
      name: '',
    })

    const rules = {
      name: [
        {
          required: true,
          message: 'Please input the pocket name',
          trigger: 'change',
        },
      ],
    }

    const handleFinish = values => {
      console.log('handleFinish called')
      // console.log('values' + values)
      // console.log('pocketForm' + pocketForm)
      // console.log(values, pocketForm)
      store.dispatch('consumer/storeMyNewPocket', {
        name: pocketForm.name,
      }).then(response => {
        console.log('back from the store')
        console.log(response)
        notification.success({
          message: "Pocket created successfully",
          description: "nice!",
          duration: 2000,
        })

        router.go(-1) // go back one step in the history
      }, error => {
        console.error('Error saving create pocket form')
        console.error(error)
        console.error(error.toString())
        notification.error({
          message: 'Could not save new pocket',
          description: error.toString(),
          duration: 2000,
        })
        // this.createPocketForm.setFields(errors)
        //https://stackoverflow.com/questions/60024729/vue-ant-design-display-client-side-as-well-as-server-side-validations-in-ants

        // todo: need to deal with this at some point
        // let thisForm = ref("createPocketForm").setFields({
        //   name: "name",
        //   value: "",
        //   errors: ["Too high"],
        // })
        // console.log('About to show form value')
        // console.log(thisForm.value)
          // .setFields(errors)

        // thisForm.value
      })
    }

    const handleFinishFailed = errors => {
      console.log('handle finish failed called')
      console.log(errors)
    }

    return {
      labelCol: {
        span: 2,
      },
      wrapperCol: {
        span: 8,
      },
      pocketForm,
      rules,
      // onSubmit,
      // onSubmitFailed,
      handleFinish,
      handleFinishFailed,
    }
  },
  methods: {
    // doSomethingFun() {
    //   console.log('do something fun called')
    //   console.log(this.$refs.createPocketForm)
    //   // this.$refs.createPocketForm.setFields({
    //   //   name: "name",
    //   //   value: "",
    //   //   errors: ["Too high"],
    //   // })
    // },
  },
})
</script>
