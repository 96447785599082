<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
<!--        <div class="card-placeholder">-->
<!--          <div class="card-header"><vb-headers-heading :data="{ title: 'Create New Pocket' }" /></div>-->
<!--        </div>-->
        <div class="card">
          <div class="card-header"><vb-headers-card-header :data="{ title: 'New Pocket' }" /></div>
          <div class="card-body"><app-create-pocket-form /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VbHeadersHeading from '@/@vb/widgets/Headers/Heading'
import VbHeadersCardHeader from '@/@vb/widgets/Headers/CardHeader'
// import VbForms1 from '@/@vb/widgets/Forms/1'
import AppCreatePocketForm from '@/components/Consumer/Pockets/CreatePocket'

export default {
  name: "ConsumerPocketCreate",
  components: {
    // VbHeadersHeading,
    VbHeadersCardHeader,
    AppCreatePocketForm,
  },
}
</script>

<style scoped>

</style>
